<template>
  <Modal v-if="modal" class="DriversConfig__Modal" @close="close">
    <template #header>
      <div class="DriversConfig__Modal__Header">
        <span>Configurações de Motoristas</span>
      </div>
    </template>
    <template #body>
      <div class="DriversConfig__Modal__Body">
        <div class="DriversConfig__Content">
          <p>Autorização de edição de cadastro no aplicativo</p>
          <p>Essa configuração possibilita ao motorista a edição de seu cadastro no aplicativo</p>
          <BaseRadioGroup
            v-model="allowEdit"
            name="allowEdit"
            :flex="false"
            :list="allowEditList"
          />
        </div>
        <!-- Save -->
        <BaseButton
          class="DriversConfig__Save"
          icon="save"
          color="primary-base"
          text-color="white"
          filled
          label="Salvar"
          theme="icon-left"
          @click="handleSave"
        />
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions } from 'vuex'
import { BaseButton, BaseRadioGroup } from '@/components/atoms'
import { Modal } from '@/components/molecules'

import { drivers } from '@/api'

export default {
  name: 'DriversConfig',
  components: {
    BaseButton,
    BaseRadioGroup,
    Modal,
  },
  data() {
    return {
      modal: false,
      allowEdit: true,

      allowEditList: [
        {id: 0, name: 'Habilitar edição de cadastro no aplicativo do motorista', value: true},
        {id: 1, name: 'Desabilitar edição de cadastro no aplicativo do motorista', value: false}
      ]
    }
  },
  watch: {
    modal(val) {
      if (val) {
        this.getDriverCanEditProfile()
      }
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),


    //Actions
    async getDriverCanEditProfile(){
      this.setLoading(true)
      await drivers.getDriverCanEditProfile((res) => {
        this.allowEdit = res.driverCanEditProfile
        return res
      }, (e) => e, () => this.setLoading(false))
    },

    async setDriverCanEditProfile(){
      this.setLoading(true)
      await drivers.setDriverCanEditProfile({allow_edit: this.allowEdit}, (r) => {
        this.close()
        return r
      }, (e) => e, () => this.setLoading(false))
    },

    //Handlers
    open() {
      this.modal = true
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    handleSave() {
      this.setDriverCanEditProfile()
    },

  }
}
</script>
<style lang="scss" scoped>
.DriversConfig {

  &__Content {
    margin-bottom: 5rem;
    p {
      margin-bottom: 1rem;
      font-weight: $font-weight-bold;
    }
  }

  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      max-height: 90vh;
    }

    &__Header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

  }
}
</style>

<template>
  <Modal v-if="modal" class="DriversEnableDeviceLogin__Modal" @close="close">
    <template #header>
      <div class="DriversEnableDeviceLogin__Modal__Header">
        <span>Liberação de login</span>
        <span>(Motorista: {{ driver.name }})</span>
      </div>
    </template>
    <template #body>
      <div class="DriversEnableDeviceLogin__Modal__Body">
        <div class="DriversEnableDeviceLogin__Content">
          <p>Esta ação liberará o acesso do motorista em um novo dispositivo móvel.</p>
          <BaseTextarea
            v-model="text"
            label="Insira a justificativa:"
            :max-height="120"
          />
        </div>
        <!-- Save -->
        <div class="DriversEnableDeviceLogin__Buttons">
          <BaseButton
            class="DriversEnableDeviceLogin__Cancel"
            color="dark"
            text-color="white"
            filled
            label="Cancelar"
            theme="icon-left"
            @click="close"
          />
          <BaseButton
            class="DriversEnableDeviceLogin__Save"
            icon="save"
            color="dark"
            text-color="white"
            filled
            label="Confirmar"
            theme="icon-left"
            :disabled="text.length === 0"
            @click="handleSave"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions } from 'vuex'
import { BaseButton, BaseTextarea } from '@/components/atoms'
import { Modal } from '@/components/molecules'

import { drivers } from '@/api'

export default {
  name: 'DriversEnableDeviceLogin',
  components: {
    BaseButton,
    BaseTextarea,
    Modal,
  },
  data() {
    return {
      modal: false,
      text: '',
      driver: {
        id: null,
        name: null
      }
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),


    //Actions
    async setDriverEnableDeviceLogin(){
      this.setLoading(true)
      await drivers.setDriverEnableDeviceLogin(this.driver, {
        observation: this.text
      }, (r) => {
        this.close()
        return r
      }, (e) => e, () => this.setLoading(false))
    },

    //Handlers
    open(driver) {
      this.driver = driver
      this.modal = true
      this.$emit('open')
    },

    close() {
      this.text = ''
      this.driver = {}
      this.modal = false
      this.$emit('close')
    },

    handleSave() {
      this.setDriverEnableDeviceLogin()
    },

  }
}
</script>
<style lang="scss" scoped>
.DriversEnableDeviceLogin {

  &__Content {
    margin-bottom: 1rem;
    p {
      margin-bottom: 1rem;
      font-weight: $font-weight-bold;
    }
  }

  &__Buttons {
    display: flex;
    /deep/ .BaseButton {
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }

  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      max-height: 90vh;
    }

    /deep/ .Modal__containerHeader {
      @media #{$mobile-view} {
        flex-direction: column;
        height: 50px;
      }
    }

    /deep/ .Modal__containerBody {
      @media #{$mobile-view} {
        height: calc(100% - 50px);
      }
    }

    &__Header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }

      @media #{$mobile-view} {
        flex-direction: column;
        height: 50px;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

  }
}
</style>

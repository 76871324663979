<template>
  <main class="Drivers">
    <TheSubHeader
      icon="profile-driver"
      title="Motoristas"
      page-title-tag="Drivers"
      type="filter"
      :actions="['export', 'addnew']"
      organizer-gap="1rem"
      organizer-columns="3fr 1fr 1fr"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="permissions && permissions.drivers && permissions.drivers.mapPageButton"
          icon="map-pin"
          color="dark"
          label="Mapa de Motoristas"
          :track="trackSubheaderButtonString('Drivers', 'Drivers Map')"
          @click="goTo('/map')"
        />
      </template>
      <template #afterButtons>
        <div v-if="hasSettingsPermission" class="Config" @click="handleDriversConfig">
          <BaseButton
            icon="settings"
            color="primary-base"
            label="Configuração"
            class="Config__Button"
          />
          <Icon name="settings" color="primary-base" class="Config__Icon" />
        </div>
      </template>
      <template #filter>
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filter.id"
          name="Motorista"
          label="Motorista"
          placeholder="Pesquisar"
          multiple
          suggestions="searchDrivers"
          @input="resetSearch()"
        />
        <!-- Transporters -->
        <BaseTreeSelect
          v-model="filter.transporter_id"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione"
          multiple
          :select-options="transporters"
          @input="resetSearch()"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filter.status_id"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :select-options="countOpt"
          @input="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Drivers__Content">
      <div class="Drivers__Table">
        <DataTable
          :header="drivers.data.headers"
          :table="drivers.data.content"
          is-sticky
          @edit="editDriver"
          @pdf="download"
          @releaseDevice="handleDriversEnableDeviceLogin"
          @unlinkVehicle="handleUnlinkVehicle"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>

    <Dialog
      v-if="confirmUnlinkVehicle.isVisible"
      :warning="confirmUnlinkVehicle.message"
      label-confirm="Sim"
      label-cancel="Não"
      @confirm="unlinkVehicle"
      @cancel="confirmUnlinkVehicle.isVisible = false"
    />

    <!-- Routes History -->
    <DriversConfig ref="DriversConfig" />

    <DriversEnableDeviceLogin ref="DriversEnableDeviceLogin" />
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import { Pagination, Dialog, DataTable, FiltersTag } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { drivers, vehicles } from '@/api'

import DriversConfig from './DriversConfig'
import DriversEnableDeviceLogin from './DriversEnableDeviceLogin'

export default {
  name: 'Drivers',
  components: {
    DataTable,
    BaseButton,
    BaseTreeSelect,
    DriversConfig,
    DriversEnableDeviceLogin,
    TheSubHeader,
    Pagination,
    Dialog,
    FiltersTag
  },
  data() {
    return {
      filter: {
        id: [],
        transporter_id: [],
        status_id: null
      },
      countOpt: [{ id: 0, name:'Todos' }, { id: 1, name:'Ativo' }, { id: 2, name:'Inativo' }, { id: 3, name:'Pendente' }, { id: 4, name:'Incompleto' },  { id: 7, name:'Aguardando Beneficiário' }],
      allTags: [],
      confirmUnlinkVehicle: {
        isVisible: false
      }
    }
  },

  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      stores: state => state.stores.stores,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions
    }),
    
    ...mapGetters({
      isMobile: 'userAgent/isMobile',
      drivers: 'drivers/drivers'
    }),

    hasSettingsPermission() {
      return this.permissions?.drivers?.configButton
    }
  },
  
  mounted() {
    this.fetchTransporters()
    this.fetchStores()
    this.search()
  },

  methods:{
    ...mapActions({
      fetchStores: 'stores/fetchStores',
      fetchTransporters: 'transporters/fetchTransporters',
      fetchDrivers: 'drivers/fetchDrivers',
      deleteDriver: 'drivers/deleteDriver',
      setParameters: 'queryString/setParameters',
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    editDriver(row) {
      this.openInNewTab(`/drivers/${row.id}`)
    },

    async download(data) {
      this.setLoading(true)
      await drivers.downloadDocs(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    updateTags(){
      this.allTags = [...this.filter.id, ...this.filter.transporter_id]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filter.id = this.filter.id.filter((tag) => tag.name !== name)
      this.filter.transporter_id = this.filter.transporter_id.filter((tag) => tag.name !== name)
      

      this.resetSearch()
    },

    clearAllTags(){
      this.filter.allTags = []
      this.filter.id = []
      this.filter.transporter_id = []
      this.filter.store_id = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    search(){

      const params = {
        obj: {
          id: this.filter.id,
          'filter[transporterDriverPivot][transporter_id]': this.filter.transporter_id,
          status_id: this.filter.status_id && this.filter.status_id.id
        },
        id: this.transporterId,
        paginate: this.paginate,
        page: this.page
      }
      
      this.updateTags()
      this.setParameters(this.filter)
      this.fetchDrivers(params).then(() => this.setPagination(this.drivers))
      
    },

    handleDriversConfig() {
      this.$mixpanel.track(this.trackSubheaderButtonString('Drivers', 'Drivers Sign Up Config'))
      this.$refs.DriversConfig?.open()
    },

    handleDriversEnableDeviceLogin(data) {
      this.$refs.DriversEnableDeviceLogin?.open(data)
    },

    goTo(route) {
      this.$router.push(route)
    },

    handleUnlinkVehicle(data) {
      this.confirmUnlinkVehicle = {
        id: data.id,
        message: `Tem certeza que deseja remover o veículo de placa ${data['vehicle.plate']} do motorista ${data.name}`,
        isVisible: true
      }
    },

    async unlinkVehicle() {
      this.setLoading(true)
      await vehicles.unlinkVehicle({
        driver_id: this.confirmUnlinkVehicle.id
      }, () => {
        this.search()
      }, () => {
        this.setLoading(false)
      }, () => {
        this.confirmUnlinkVehicle.isVisible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Drivers {
  &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
}

.Config {
  height: 80px;
  margin-top: 3px;
  @media #{$mobile-view} {
    margin-top: 0;
    height: auto;
  }
 
  &__Button {
    display: none;
    margin: .5rem 0;
    width: auto;
    @media #{$mobile-view} {
      display: flex;
    }
  }
  &__Icon {
    cursor: pointer;
    width: 30px;
    display: block;
    @media #{$mobile-view} {
      display: none;
    }
  }
}
</style>
